import { lazy, Suspense, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import GlobalStyles from "../globalStyles";
import routes from "./config";

const Router = () => {
  const [isAuthenticated, userHasAuthenticated] = useState(JSON.parse(localStorage.getItem('isAuthenticated')));

  const [routesArray, setRoutes] = useState(routes);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      if(!isAuthenticated || isAuthenticated === null) {
        let routesArray = routes.map(route => {
          if(route.component === 'Simulate') {
            return {
              path: ["/simulate"],
              exact: true,
              component: "Auth",
            }
          }
          else {
            return route;
          }
        });
        setRoutes(routesArray);
      }
    } catch (e) {
      alert(e);
    }
  }

  return (
    <Suspense fallback={null}>
      <GlobalStyles />
      <Switch>
        {routesArray.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default Router;
